<template>
  <div>
    <div class="type-box">
      <div class="_Select">
        <span>年份：</span>
        <el-date-picker
          v-model="year"
          format="yyyy"
          value-format="yyyy"
          type="year"
          placeholder="选择年"
        ></el-date-picker>
      </div>
      <div class="_Select">
        <span>公司：</span>
        <el-select v-model="compny" clearable placeholder="请选择">
          <el-option
            v-for="item in companyUserList"
            :key="item.id"
            :label="item.companyName"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
      <!-- <div class="_Select">
        <span style="width:250px">考核细则名称：</span>
        <el-input v-model="name" placeholder="请输入"></el-input>
      </div>-->
      <el-button type="success" icon="el-icon-search" class="find" @click="handleQuery">搜索</el-button>
    </div>
    <el-table
      :data="tableData.slice(start, end)"
      :header-cell-style="{ background: '#E8EBF4', color: '#5C5C5C' }"
      style="width: 98%"
      id="table"
      :height="elementHeight"
      border
    >
      <el-table-column type="index" width="100" label="序号"></el-table-column>
      <el-table-column prop="compName" label="公司"></el-table-column>
      <el-table-column prop="module" label="问题模块"></el-table-column>
      <el-table-column prop="question" label="考核细则问题"></el-table-column>
      <el-table-column prop="lawAsk" label="审核意见"></el-table-column>
      <el-table-column prop="auditResult" label="审核结果">
        <template slot-scope="scope">
          <span v-if="scope.row.auditResult == 0">不符合</span>
          <span v-else-if="scope.row.auditResult == 1">符合</span>
          <span v-else-if="scope.row.auditResult == 2">纠正</span>
          <span v-else>改进</span>
        </template>
      </el-table-column>
      <el-table-column prop="violatedTerms" label="扣分"></el-table-column>
    </el-table>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-sizes="[10, 20, 50]"
      :page-size="curSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="tableData.length"
      class="_Pagination"
    ></el-pagination>
  </div>
</template>

<script>
import { get } from '../../api/http'
export default {
  data() {
    return {
      elementHeight: 0,
      tableData: [],
      start: 0,
      end: 10,
      curSize: 10,
      currentPage: 1,
      year: new Date().getFullYear() + '',
      compny: '',
      name: '安全考核',
      companyUserList: [],
    }
  },
  mounted() {
    this.companyUserList = this.$store.state.companyUserList
    this.getElementHeight()
    this.getList()
  },
  methods: {
    getElementHeight() {
      this.$nextTick(() => {
        this.elementHeight =
          window.innerHeight -
          (document.getElementById('table').offsetTop + 120)
      })
    },
    getList() {
      let data = {
        CompId: this.compny,
        Years: this.year,
        Name: this.name,
      }
      get('/api/NewExamineRules/GetExamRuleCensus', data).then((res) => {
        this.tableData = res.data
      })
    },
    handleQuery() {
      this.getList()
    },
    handleSizeChange(e) {
      this.curSize = e
      this.start = (this.currentPage - 1) * this.curSize
      this.end = this.start + this.curSize
    },
    handleCurrentChange(e) {
      this.start = (e - 1) * this.curSize
      this.end = this.start + this.curSize
    },
  },
}
</script>

<style lang="less" scoped >
.type-box {
  position: relative;
  display: flex;
  padding-bottom: 10px;
  align-items: center;
  .find {
    margin-left: 10px;
  }
}
</style>